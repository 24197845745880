import React, { useState } from "react";
import { Waypoint } from "react-waypoint";

// Adds the effect ready effects to your modules
export const AnimateModules = props => {
  const [effect, setEffect] = useState("");

  function handleEnter(props) {
    if (props.currentPosition === "inside") {
      return setEffect("effect--ready");
    }
    if (props.previousPosition === "below") {
      return setEffect("effect--ready");
    }
  }

  function handleLeave(props) {
    if (props.currentPosition === "below") {
      return setEffect("");
    }
  }

  if (props.disableAnimation && props.disableAnimation === true) {
    return <div className={`${props.classes}`}>{props.children}</div>;
  } else {
    return (
      <Waypoint onEnter={handleEnter} onLeave={handleLeave} bottomOffset={"10%"}>
        <div className={`${props.classes} effect effect--default ${effect}`}>{props.children}</div>
      </Waypoint>
    );
  }
};
