import { PostsDisplayPost, PeopleDisplayPerson } from "utils/functions";

// Helps us return the neccessary tile
export const DisplayByPostType = (type, data) => {
  if (type === "post") {
    return <PostsDisplayPost post={data} />;
  } else if (type === "person") {
    return <PeopleDisplayPerson post={data} />;
  }
};
