//  Adds linkline to all neccessary links
export const AddLinkLine = () => {
  const links = document.querySelectorAll("a:not(.noline):not(.line-added)");
  links.forEach(function (el) {
    if (el.closest(".noline")) {
      return;
    }

    const icon = el.querySelector(".fa, .fas, .far, .fal, .fab");

    if (el.querySelector("img") < 1) {
      const linkHTML = "<span class='linkline'>" + el.textContent + "</span>";
      el.innerHTML = linkHTML;
      el.classList.add("line-added");
      if (icon) {
        if (icon.classList.contains("fa--left")) {
          el.querySelector(".linkline").before(icon);
        } else {
          el.querySelector(".linkline").after(icon);
        }
      }
    }
  });
};
