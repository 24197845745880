// Set colours bg classes

export const ThemeSetColorsBg = (bg_colour, with_padding) => {
  let text = "";
  let classes = "";
  if (bg_colour && bg_colour !== "none") {
    if (bg_colour === "black") {
      text = "color-white";
    } else {
      text = " ";
    }
    if (with_padding) {
      classes = "pt pb ";
    }
    classes = classes + " module--bg bg-" + bg_colour + " " + text;
  }
  return classes ?? "";
};
