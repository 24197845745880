import { FetchWPQuery } from "./FetchWPQuery";
import { FetchADQuery } from "./FetchADQuery";

export const FetchSearchQuery = async params => {
  // We need to get the search results from the WP search endpoint
  const newData = FetchWPQuery(`search`, params, true);
  const returnPageIDs = await newData;

  //  We need to return a bunch of IDs, and get them ready for endpoint 2
  const pageIDs = [];
  returnPageIDs.data.forEach((element, index) => {
    pageIDs.push(element.id);
  });

  // Lets update our parameters now, because we want to send most of the args
  // (minus the search, plus the ids / post any)
  const pageIDsJoin = pageIDs.join(",");
  delete params["search"];
  params = { ...params, post__in: pageIDsJoin, post_type: "any" };

  // Return the search results
  const searchResults = FetchADQuery(`search-result`, params);
  return { data: await searchResults, totalPages: returnPageIDs.totalPages ?? 1 };
};
