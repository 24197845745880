import { TransformUrl } from "./";

// Generate categories markup html to use
export const GenerateCategoriesMarkup = (categories, prefix) => {
  let categories_html = "";
  for (let index = 0; index < categories.length; index++) {
    const item = categories[index];
    categories_html += `<a href="/${prefix}/${TransformUrl(item.taxonomy)}/${TransformUrl(item.slug)}">`;
    categories_html += `${item.name}`;
    categories_html += `</a>, `;
  }
  categories_html = categories_html.slice(0, -2);
  return categories_html;
};
