// Util Imports
import { ThemeDisplayButton } from "utils/theme";

// Shared Imports
import Searchform from "parts/shared/SearchForm";

// Mob Nav Header
const HeaderMobNav = ({ main_nav, additional_nav, header_button }) => {
  const handleClick = e => {
    e.currentTarget.classList.toggle("sitenav__item--open");
  };
  return (
    <nav className="mobnav" id="mobnav">
      <div className="mobnav__bd bd pt--half pb--half">
        <nav className="sitenav sitenav--mobile font-bold">
          <ul className="noline">
            {typeof main_nav !== "undefined"
              ? main_nav.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={handleClick}
                      className={`sitenav__item ${item.add_dropdown ? "sitenav__item--hasdropdown" : ""}`}>
                      {ThemeDisplayButton(item.link, "sitenav__link", false)}
                      <div className="sitenav__item__dropdownarrow"></div>
                      {item.add_dropdown ? (
                        <div className="sitenav__item__dropdown dropdown">
                          <ul className="dropdown__list">
                            {item.dropdown
                              ? item.dropdown.child_links.map((child, child_index) => {
                                  return (
                                    <li key={child_index}>
                                      {ThemeDisplayButton(child.child_link, "dropdown__link", false)}
                                    </li>
                                  );
                                })
                              : false}
                          </ul>
                        </div>
                      ) : (
                        false
                      )}
                    </li>
                  );
                })
              : false}
            {additional_nav
              ? additional_nav.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={`sitenav__item ${item.add_dropdown ? "sitenav__item--hasdropdown" : ""}`}>
                      {ThemeDisplayButton(item.link, "sitenav__link", false)}
                      <div className="sitenav__item__dropdownarrow"></div>
                      {item.add_dropdown ? (
                        <div className="sitenav__item__dropdown dropdown">
                          <ul className="dropdown__list">
                            {item.dropdown
                              ? item.dropdown.child_links.map((child, child_index) => {
                                  return (
                                    <li key={child_index}>
                                      {ThemeDisplayButton(child.child_link, "dropdown__link", false)}
                                    </li>
                                  );
                                })
                              : false}
                          </ul>
                        </div>
                      ) : (
                        false
                      )}
                    </li>
                  );
                })
              : false}
            <li className="sitenav__item sitenav__item--btn">
              {ThemeDisplayButton(header_button, "sitenav__btn btn btn--gold", false)}{" "}
            </li>
          </ul>
        </nav>
        <div className="mobnav__search">
          <Searchform />
        </div>
      </div>
    </nav>
  );
};

export default HeaderMobNav;
