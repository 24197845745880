// Display share icons component
export const ThemeDisplayShareIcons = ({ post, addclass = false }) => {
  const url = window.location.href;
  const title = post.title.rendered;

  const newWindow = (e, url) => {
    e.preventDefault();
    window.open(url, "share-dialog", "width=550,height=436");
  };
  return (
    <nav className={`socialicons ${addclass}`}>
      <ul className="socialicons__list text-sml">
        <li>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
            onClick={e => newWindow(e, `https://www.facebook.com/sharer/sharer.php?u=${url}`)}
            className="sociallink sociallink--facebook sociallink--popup noline noanimation">
            <span className="sociallink__icon">
              <span className="fab fa-facebook-f"></span>
            </span>
            <span className="sociallink__text">Share</span>
          </a>
        </li>
        <li>
          <a
            href={`https://twitter.com/intent/tweet?url=${url}`}
            onClick={e => newWindow(e, `https://twitter.com/intent/tweet?url=${url}`)}
            className="sociallink sociallink--twitter sociallink--popup noline noanimation">
            <span className="sociallink__icon">
              <span className="fab fa-twitter"></span>
            </span>
            <span className="sociallink__text">Share</span>
          </a>
        </li>
        <li>
          <a
            href={`https://www.linkedin.com/shareArticle?url=${url}&amp;title=${title}`}
            onClick={e => newWindow(e, `https://www.linkedin.com/shareArticle?url=${url}&amp;title=${title}`)}
            className="sociallink sociallink--linkedin sociallink--popup noline noanimation">
            <span className="sociallink__icon">
              <span className="fab fa-linkedin-in"></span>
            </span>
            <span className="sociallink__text">Share</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};
