import React, { useState } from "react";

// Search form
const SearchForm = ({ searchTerm }) => {
  const [updateSearchTerm, setUpdateSearchTerm] = useState(searchTerm);

  // onchange of input update
  const onChange = event => {
    setUpdateSearchTerm(event.target.value);
  };

  return (
    <form className="searchform" action="/" method="get">
      <div className="searchform__input">
        <input
          type="search"
          name="s"
          id="s"
          value={updateSearchTerm ?? ""}
          className=""
          placeholder="Start typing ..."
          autoComplete="off"
          onChange={onChange}
        />
        <button className="searchform__submit noline">
          <span className="visuallyhidden">Search</span>
          <span className="far fa-search fa--nopad"></span>
        </button>
      </div>
    </form>
  );
};

export default SearchForm;
