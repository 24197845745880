import { ThemeSetColorsBg, ThemeSetColorsAccent } from "./";

// Set colours from bg and accent functions
export const ThemeSetColors = (module, with_padding = true) => {
  const bg_colour = module && module.bg_colour ? module.bg_colour : false;
  const accent_colour = module && module.accent_colour ? module.accent_colour : false;

  const bg_classes = ThemeSetColorsBg(bg_colour, with_padding);
  const accent_classes = ThemeSetColorsAccent(accent_colour);
  return bg_classes + " " + accent_classes;
};
