import { FetchWPQuery } from "./";
export const FetchRelatedPosts = async (postLimit, posts, data) => {
  let setRelatedPosts = [];
  let setExcludePosts = [data.id];
  let relevant_categories = [];
  if (posts) {
    posts.forEach(element => {
      if (element.post_status === "publish") {
        setRelatedPosts.push(element.ID);
        setExcludePosts.push(element.ID);
      }
    });
  }
  if (data.type === "post") {
    data.categories.forEach(element => {
      relevant_categories.push(element.term_id);
    });
  }
  if (relevant_categories) {
    const getFurtherPosts = await FetchWPQuery(`posts`, {
      order: "desc",
      orderby: "date",
      status: "publish",
      post_type: "post",
      category__in: relevant_categories,
      exclude: setExcludePosts ? setExcludePosts.join(",") : "",
      per_page: postLimit - setRelatedPosts.length ?? 0
    }).then(res => {
      return res;
    });
    if (setRelatedPosts.length < postLimit) {
      if (getFurtherPosts) {
        getFurtherPosts.forEach(item => {
          setRelatedPosts.push(item.id);
          setExcludePosts.push(item.id);
        });
      }
    }
    if (setRelatedPosts.length < postLimit) {
      const getEvenMorePosts = await FetchWPQuery(`posts`, {
        order: "desc",
        orderby: "date",
        status: "publish",
        post_type: "post",
        exclude: setExcludePosts ? setExcludePosts.join(",") : "",
        per_page: postLimit - setRelatedPosts.length
      }).then(res => {
        return res;
      });
      if (getEvenMorePosts) {
        getEvenMorePosts.forEach(item => {
          setRelatedPosts.push(item.id);
          setExcludePosts.push(item.id);
        });
      }
    }
  }
  return setRelatedPosts;
};
