import { NavLink } from "react-router-dom";
import { TransformUrl } from "utils/helpers";

// Display button component
export const ThemeDisplayButton = (link, classes = false, linkline = true) => {
  if (typeof link === "undefined") return;
  return (
    <NavLink
      className={({ isActive }) => (isActive ? ` current ${classes}` : ` ${classes}`)}
      to={TransformUrl(link.url)}
      target={link.target}>
      {link.title}
    </NavLink>
  );
};
