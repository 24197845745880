import { Helmet, HelmetProvider } from "react-helmet-async";
import parse from "html-react-parser";

export const HeadManipulation = ({ options }) => {
  // Inject Yoast into head
  return (
    <HelmetProvider>
      <Helmet>{options && options.yoast_head ? parse(options.yoast_head) : false}</Helmet>
    </HelmetProvider>
  );
};
