export default function ConvertModuleNameToFilename(words) {
  var separateWord = words.replace(/_/g, " ");

  separateWord = separateWord.toLowerCase().split(" ");
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }

  return separateWord.join("");
}
