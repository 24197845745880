import { motion } from "framer-motion";
import React, { Suspense, useEffect, useState } from "react";

import { GetFramerMotionConfig } from "utils/configs";
import { SetAppHeight, AddLinkLine } from "utils/helpers";
import { HeaderScripts } from "./HeaderScripts";

const Page = React.lazy(() => import("parts/templates/Page"));
const Contact = React.lazy(() => import("parts/templates/Contact"));
const People = React.lazy(() => import("parts/templates/People"));
const Category = React.lazy(() => import("parts/templates/Category"));
const Team = React.lazy(() => import("parts/templates/Team"));
const Search = React.lazy(() => import("parts/templates/Search"));
const Blog = React.lazy(() => import("parts/templates/Blog"));
const Post = React.lazy(() => import("parts/templates/Post"));
const Person = React.lazy(() => import("parts/templates/Person"));
const Footer = React.lazy(() => import("parts/shared/footer/Footer"));

export const TemplateLoader = ({ data, template, containerRef }) => {
  const [loadScripts, setLoadScripts] = useState(false);

  // Add linklines when a template is loaded, (with a delay to ensure they work)
  useEffect(() => {
    if (data) {
      AddLinkLine();
      if (loadScripts === false) {
        HeaderScripts(data);
        setLoadScripts(true);
      }
    }
  }, [data, loadScripts]);
  // config for page transitions
  const framerConfig = GetFramerMotionConfig();

  // Set app height (e.g. an 100vh that works on mobiles)
  window.addEventListener("resize", SetAppHeight);

  SetAppHeight();

  return (
    <Suspense>
      <motion.div
        key={data.id}
        variants={framerConfig}
        initial="initial"
        animate="enter"
        exit="exit"
        onAnimationStart={e => {
          containerRef.current.classList.add("page-transitioning");
        }}
        onAnimationComplete={e => {
          if (e === "enter") {
            containerRef.current.classList.remove("page-transitioning");
          }
        }}>
        {template === "Contact" ? <Contact data={data} /> : false}
        {template === "Page" ? <Page data={data} /> : false}
        {template === "Blog" ? <Blog data={data} /> : false}
        {template === "People" ? <People data={data} /> : false}
        {template === "Person" ? <Person data={data} /> : false}
        {template === "Post" ? <Post data={data} /> : false}
        {template === "Category" ? <Category data={data} /> : false}
        {template === "Team" ? <Team data={data} /> : false}
        {template === "Search" ? <Search data={data} /> : false}
        <Footer footer={data.options} bloginfo={data.blog_info} />
      </motion.div>
    </Suspense>
  );
};
