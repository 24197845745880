// generate mob nav height and status
export const GenerateMobNavHeight = (mobNavIsOpen, toggleNav = true) => {
  const mobnav = document.querySelector(".mobnav");
  const siteHeaderBd = document.querySelector(".siteheader__bd");

  // on mobiles, re render occurs, we dont want to do anything here if we are simply re rendering due to orientation!
  if (mobnav && siteHeaderBd) {
    // used to generate height
    let mobNavHeight = window.innerHeight - siteHeaderBd.getBoundingClientRect().height;

    if (toggleNav) {
      if (!mobNavIsOpen.current) {
        mobNavIsOpen.current = true;
      } else {
        mobNavIsOpen.current = false;
      }
    }

    if (mobNavIsOpen.current) {
      mobnav.style.height = `${mobNavHeight}px`;
      document.body.classList.add("mobnavopen");
    } else {
      mobnav.style.height = `0`;
      document.body.classList.remove("mobnavopen");
    }
  }
};
