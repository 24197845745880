import { NavLink } from "react-router-dom";
import { ThemeDisplayImage } from "utils/theme";
import { TransformUrl } from "utils/helpers";

// Return markup for page tiles
export const PagesDisplayPage = ({ post }) => {
  if (typeof post === "undefined") return;
  const image = post.acf.page_header_image;
  const link = post.link;
  const title = post.acf.page_heading ? post.acf.page_heading : post.title.rendered;
  const page_leader = post.acf.page_leader;

  return (
    <article className="tile tile--page">
      <NavLink className="tile__link noline" to={TransformUrl(link)}>
        {post.acf && post.acf.page_header_image ? (
          <ThemeDisplayImage image={image} image_style="inline" image_size="sm" image_classes="tile__img mb--em" />
        ) : (
          false
        )}
        <h3 className="tile__heading heading-reg">{title}</h3>
        <div className="tile__description text-sml o-50" dangerouslySetInnerHTML={{ __html: page_leader }}></div>
        <div className="tile__cta">
          <NavLink className="tile__link text-link" to={TransformUrl(link)}>
            View {post.type}
          </NavLink>
        </div>
      </NavLink>
    </article>
  );
};
