import { Fancybox } from "@fancyapps/ui";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import LazyLoad from "vanilla-lazyload";
import { ConditionalWrapper } from "utils/helpers";

import "@fancyapps/ui/dist/fancybox/fancybox.css";

const lazyloadConfig = {
  elements_selector: ".lazy"
};

// Only initialize it one time for the entire application
if (!document.lazyLoadInstance) {
  document.lazyLoadInstance = new LazyLoad(lazyloadConfig);
}

// Display image component
export const ThemeDisplayImage = ({
  image,
  image_style,
  image_size,
  image_classes,
  enable_image_popup,
  gallery_id
}) => {
  // Update lazyLoad after first rendering of every image
  useEffect(() => {
    document.lazyLoadInstance.update();
  });
  if (!image) return;

  const image_array = image ? image : [];

  const image_fullsize = image["url"];
  const image_url = image_array["sizes"][image_size] ?? "";
  const image_size_mobile = image_size === "icon" ? image_size : "sm"; // use sm image (600px) on mobile, unless we are doing an icon
  const image_width = image_array["sizes"][image_size + "-width"];
  const image_height = image_array["sizes"][image_size + "-height"];
  const image_url_mobile = image_array["sizes"][image_size_mobile];
  const image_width_mobile = image_array["sizes"][image_size_mobile + "-width"];
  const image_caption = image["caption"];
  const image_alt = image_array["alt"];

  if (enable_image_popup) {
    Fancybox.bind('a[href*=".jpg"],a[href*=".jpeg"],a[href*=".png"],a[href*=".gif"]', {
      Carousel: {
        infinite: false,
        Thumbs: false
      },
      Thumbs: false
    });
  }
  return (
    <div className={`mediaimg ${image_classes} mediaimg--${image_style}`}>
      <ConditionalWrapper
        condition={enable_image_popup}
        wrapper={children => (
          <NavLink data-fancybox={gallery_id} data-caption={`${image_caption}`} to={image_fullsize}>
            {children}
          </NavLink>
        )}>
        <img
          className="mediaimg__img mediaimg__img--inline lazy"
          data-srcset={`${image_url_mobile} ${image_width_mobile}w, ${image_url}`}
          data-sizes={`${image_url_mobile} ${image_width_mobile}px, ${image_url} ${image_width}vw`}
          width={`${image_width}`}
          height={`${image_height}`}
          alt={`${image_alt}`}
        />
      </ConditionalWrapper>
    </div>
  );
};
