import { ThemeSetColors } from "utils/theme";

// Generate classes based from our module styling ACF options (bg and accent)
export const GenerateClasses = (header_bg_type, header_styling, pageheader_size) => {
  let headerclass;
  let bodyclass;
  if (header_bg_type && header_bg_type !== "default") {
    headerclass = "mb color-white pageheader--bg";
    bodyclass = "pt--double pb--double";
  } else {
    const bg_classes = ThemeSetColors(header_styling, false);
    if (
      (header_styling && header_styling.bg_colour && header_styling.bg_colour !== "none") ||
      pageheader_size === "large"
    ) {
      headerclass = `mb pageheader--bg ${bg_classes} `;
      bodyclass = "pt--double pb--double";
    } else {
      headerclass = `mt--double mb--double ${bg_classes} `;
      bodyclass = "";
    }
  }

  return {
    headerclass,
    bodyclass
  };
};
