// Returns the slug current slug, repalces the url if we are working locally
export const GetCurrentSlug = () => {
  let TransformUrl = window.location.href;
  TransformUrl = TransformUrl.replace(`${process.env.REACT_APP_FRONTEND_URI}`, "").split("?")[0].split("#")[0];
  TransformUrl = TransformUrl.replace(/\/$/, "");
  if (TransformUrl === "") {
    return "/";
  }
  return TransformUrl;
};
