import { NavLink } from "react-router-dom";
import { ThemeDisplayImage } from "utils/theme";
import { TransformUrl, GenerateCategoriesMarkup } from "utils/helpers";

// Return markup for post tiles
export const PostsDisplayPost = ({ post }) => {
  if (typeof post === "undefined") return;
  const image = post.acf.post_tile_img;
  const link = post.link;
  const title = post.title.rendered;
  const categories = post.categories;
  const categories_html = post.blog_slug ? GenerateCategoriesMarkup(categories, post.blog_slug.post_name) : "";

  return (
    <article className="tile tile--post">
      <NavLink className="tile__link noline" to={TransformUrl(link)}>
        {post.acf && post.acf.post_tile_img ? (
          <ThemeDisplayImage image={image} image_style="inline" image_size="sm" image_classes="tile__img mb--em" />
        ) : (
          false
        )}
        <h3 className="tile__heading heading-reg">{title}</h3>
      </NavLink>
      {categories_html !== "" ? (
        <div
          className="tile__categories text-sml hiddenlinkline o-50"
          dangerouslySetInnerHTML={{ __html: categories_html }}></div>
      ) : (
        false
      )}
    </article>
  );
};
