import { GetCurrentSlug } from "utils/helpers";
import { FetchADQuery } from "./FetchADQuery";
import { FetchWPQuery } from "./FetchWPQuery";

export const FetchData = async (LinkOverride = false) => {
  // Get Location, and build the content type endpoint
  const searchParams = new URLSearchParams(window.location.search);

  // Use current slug if not override is passed
  const slug = GetCurrentSlug(LinkOverride);

  // Get content typ
  const returnContentType = await FetchADQuery(`content-type`, {
    path: slug,
    s: searchParams.get("s") ? searchParams.get("s") : false,
    preview: searchParams.get("preview") ? searchParams.get("preview") : false,
    page_id: searchParams.get("page_id") ? searchParams.get("page_id") : false
  });

  // If taxonomy built the categories endpoint, otherwise build the posts endpoint
  const getUrlString =
    returnContentType.contentType === "taxonomies" || returnContentType.contentType === "categories"
      ? `${returnContentType.data.taxonomy === "category" ? "categories" : returnContentType.data.taxonomy}/${
          returnContentType.data.term_id
        }`
      : `posts`;
  // Return data from previous query
  const returnPostData = await FetchWPQuery(getUrlString, {
    path: slug,
    preview: searchParams.get("preview") ? searchParams.get("preview") : false,
    preview_id: searchParams.get("page_id") ? searchParams.get("page_id") : false,
    nonce: searchParams.get("nonce") ? searchParams.get("nonce") : false
  });

  // Categories returns as an object, posts return as an array ... cater for this!
  const transformData = returnContentType.contentType === "taxonomies" ? [returnPostData] : returnPostData;

  return { type: returnContentType, data: transformData };
};
