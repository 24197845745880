import React, { Suspense } from "react";
import { useEffect, useState } from "react";
import ConvertModuleNameToFilename from "utils/helpers/ConvertModuleNameToFilename";

export const ContentModules = ({ data, layout, moduleCount, index, change }) => {
  const [getDynamic, setDynamic] = useState();

  useEffect(() => {
    const layoutComponent = ConvertModuleNameToFilename(layout);
    const GetDynamicModule = React.lazy(() => import(`parts/modules/${layoutComponent}`));
    setDynamic(<GetDynamicModule key={`inner_component_${index}`} data={data} />);
    if (index === moduleCount - 1) {
      change(true);
    }
  }, [data, change, index, layout, moduleCount]);

  const DynamicPage = () => {
    return getDynamic;
  };

  return (
    <Suspense>
      <DynamicPage />
    </Suspense>
  );
};
