import queryString from "query-string";

export const FetchADQuery = async (endpoint, params, pagination = false) => {
  let removeParams = Object.fromEntries(Object.entries(params).filter(([_, v]) => v !== false));
  endpoint = removeParams
    ? `${process.env.REACT_APP_CMS_URI}${process.env.REACT_APP_API_ENDPOINT_AD}${endpoint}?${queryString.stringify(
        removeParams
      )}`
    : false;
  const requestPostData = await fetch(endpoint);
  const data = await requestPostData.json();
  return data;
};
