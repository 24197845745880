// This allows as to wrap any component in conditionalelement, eg:
// <ConditionalWrapper condition={1==1 ? true : false}
// wrapper={children => (
// <Wrapper>
// {children}
// </wrapper>
// <SomeElement>
// <ConditionalWrapper />
// This will wrapp 'somelement' with 'wrapper' if the condition in 'conditionalwrapper' is true
export const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);
