import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

// Util Imports
import { GenerateMobNavHeight } from "utils/helpers";

// Shared imports
import HeaderMobnav from "./HeaderMobNav";
import HeaderSitenav from "./HeaderSiteNav";
import Searchform from "../SearchForm";

// Header
const Header = ({ navigation, bloginfo, location }) => {
  const main_nav = navigation ? navigation.main_navigation : [];
  const additional_nav = navigation ? navigation.additional_navigation : [];
  const header_but = navigation ? navigation.header_button : [];
  const [searchForm, setSearchForm] = useState(false);
  let mobNavIsOpen = useRef(false);

  function showSearch(e) {
    if (searchForm) {
      return setSearchForm(false);
    }
    return setSearchForm(true);
  }

  useEffect(() => {
    mobNavIsOpen.current = false;
    GenerateMobNavHeight(mobNavIsOpen, false);
  }, [location]);

  // Start event listener to resize nav on page height change, but dont toggle it
  useEffect(() => {
    window.addEventListener(
      "resize",
      function () {
        GenerateMobNavHeight(mobNavIsOpen, false);
      },
      true
    );
  }, [mobNavIsOpen]);

  return (
    <>
      <header className="siteheader">
        <HeaderMobnav main_nav={main_nav} additional_nav={additional_nav} header_button={header_but} />
        <div className="siteheader__wrap">
          <div className="siteheader__bd bd">
            <div className="siteheader__logo noline">
              <NavLink to="/">
                <div className="siteheader__logo__img" aria-label={bloginfo ? bloginfo.name : ""}></div>
              </NavLink>
            </div>
            <div className="visuallyhidden">{bloginfo ? bloginfo.description : ""}</div>
            <nav className="siteheader__mobtools">
              <ul className="inlinenavlist noline">
                <li>
                  <button
                    onClick={() => GenerateMobNavHeight(mobNavIsOpen, true)}
                    className="mobnav-toggle"
                    title="Menu">
                    <span className="far fa-bars fa--nopad"></span>
                  </button>
                </li>
              </ul>
            </nav>
            <HeaderSitenav
              main_nav={main_nav}
              additional_nav={additional_nav}
              header_button={header_but}
              handleShowSearch={showSearch}
            />
          </div>
        </div>
        {searchForm ? (
          <div className="siteheader__search">
            <div className="siteheader__bd bd pt--em pb--em">
              <Searchform />
            </div>
          </div>
        ) : (
          false
        )}
      </header>
      <div className="siteheader siteheader--padder">
        <div className="siteheader__wrap"></div>
      </div>
    </>
  );
};

export default Header;
