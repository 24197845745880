export const FetchWPQuery = async (endpoint, params, pagination = false) => {
  let removeParams = Object.fromEntries(Object.entries(params).filter(([_, v]) => v !== false));
  // filter any preview parameters if NOT logged in
  const get_token = document.cookie;
  if (!get_token.includes("wp_token")) {
    removeParams = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => _ !== "nonce" && _ !== "preview" && _ !== "preview_id")
    );
  }

  const actualParams = new URLSearchParams(removeParams).toString();
  endpoint = removeParams
    ? `${process.env.REACT_APP_CMS_URI}${process.env.REACT_APP_API_ENDPOINT_WP}${endpoint}?${decodeURIComponent(
        actualParams
      )}`
    : false;
  const requestPostData = await fetch(endpoint);
  const data = await requestPostData.json();
  return pagination ? { data, totalPages: requestPostData.headers.get("x-wp-totalpages") ?? 1 } : data;
};
