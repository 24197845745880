import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// Util Imports
import { FetchData } from "utils/api";
import { TemplateLoader, HeadManipulation } from "utils/core";
import { GetCurrentSlug } from "utils/helpers";

// Shared Imports
import Header from "parts/shared/header/Header";

// Stylesheets
import "App.scss";
import "owl.carousel/dist/assets/owl.carousel.css";

export default function App() {
  const [posts, setPosts] = useState(false);
  const containerRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    FetchData()
      .then(response => {
        setPosts({ data: response.data[0] ?? response.data, template: response.type.returnComponent });
      })
      .then(res => {
        window.scrollTo(0, 0);
      });
  }, [location.pathname]);

  const currentSlug = GetCurrentSlug();

  return posts ? (
    <main ref={containerRef}>
      <HeadManipulation options={posts.data} />
      <Header navigation={posts.data.options} bloginfo={posts.data.blog_info} location={location} />
      <AnimatePresence mode="wait" initial={false}>
        <Routes location={location}>
          <Route
            exact
            path={`/${currentSlug}`}
            element={<TemplateLoader data={posts.data} template={posts.template} containerRef={containerRef} />}
          />
        </Routes>
      </AnimatePresence>
    </main>
  ) : (
    false
  );
}
