import { NavLink } from "react-router-dom";
import { ThemeDisplayImage } from "utils/theme";
import { TransformUrl } from "utils/helpers";

// Return markup for people tiles
export const PeopleDisplayPerson = ({ post }) => {
  if (typeof post === "undefined") return;
  const image = post.acf.person_picture;
  const role = post.acf.person_role;
  const link = post.link;
  const title = post.title.rendered;

  return (
    <div className="tile tile--person">
      <NavLink className="tile__link noline" to={TransformUrl(link)}>
        {post.acf && post.acf.person_picture ? (
          <ThemeDisplayImage image={image} image_style="bg" image_size="sm" image_classes="tile__img mb--em" />
        ) : (
          false
        )}
        <div className="tile__content">
          <h4 className="tile__name heading-reg">{title}</h4>
          {role ? <div className="tile__role heading-sml o-50">{role}</div> : false}
        </div>
      </NavLink>
    </div>
  );
};
