// Display video component
export const ThemeDisplayVideo = ({ video }) => {
  if (!video) return;
  let video_url = video.video_url;
  const autoplay_video = video.autoplay_video ? "autoplay" : "click-to-play";

  if (!video_url.includes("player")) {
    video_url = video_url.replace("vimeo", "player.vimeo");
    video_url = video_url.replace(".com/", ".com/video/");
  }

  if (video_url.includes("vimeo")) {
    if (!video_url.includes("player")) {
      video_url = video_url.replace("vimeo", "player.vimeo");
      video_url = video_url.replace(".com/", ".com/video/");
    }

    video_url =
      autoplay_video === "autoplay"
        ? video_url + "?autoplay=1&loop=1&background=1&hd=1&muted=1&autopause=false"
        : video_url;

    return (
      <div className={`mediavideo mediavideo--vimeo ${autoplay_video === "autoplay" ? "mediavideo--autoplay" : false}`}>
        <div className="mediavideo__video no-plyr">
          <iframe
            title="vimeovideo"
            src={`${video_url}`}
            width="640"
            height="360"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen></iframe>
        </div>
      </div>
    );
  } else if (video_url.includes("youtube")) {
    var regExp = /(youtu\.be\/|youtube(-nocookie)?.com\/(v\/|e\/|.*u\/\w+\/|embed\/|.*v=))([\w-]{11})/;

    var matches = video_url.match(regExp);
    video_url = "https://www.youtube-nocookie.com/embed/" + matches[4];
    video_url =
      autoplay_video === "autoplay" ? video_url + "/?autoplay=1&mute=1&controls=0&showinfo=0&loop=1" : video_url;
    return (
      <div
        className={`mediavideo mediavideo--youtube ${autoplay_video === "autoplay" ? "mediavideo--autoplay" : false}`}>
        <div className="mediavideo__video no-plyr">
          <iframe
            title="youtubevideo"
            width="560"
            height="315"
            src={video_url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen></iframe>
        </div>
      </div>
    );
  }
};
