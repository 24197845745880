export const GetFramerMotionConfig = () => {
  // Framer Motion Options
  const duration = 0.25;
  const options = {
    initial: {
      opacity: 0
    },
    enter: {
      opacity: 1,
      transition: {
        duration: duration,
        delay: 0.1
      }
    },
    exit: {
      opacity: 0,
      transition: { duration: duration }
    }
  };

  return options;
};
